<template>
	<svg class="flag-container" :width="size" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
		<g>
			<g>
				<g>
					<defs>
						<circle id="SVGID_1_" cx="100.5" cy="99.5" r="99.5"/>
					</defs>
					<clipPath id="SVGID_2_">
						<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
					</clipPath>
					<g class="st0">
						<path class="st1" d="M247.1,199H-46.1c-2.9,0-5.2-2.3-5.2-5.2V5.2c0-2.9,2.3-5.2,5.2-5.2h293.3c2.9,0,5.2,2.3,5.2,5.2v188.5
							C252.4,196.7,250,199,247.1,199z"/>
						<g>
							<path class="st2" d="M74.3,78.6H-46.1c-2.9,0-5.2-2.3-5.2-5.2V5.2c0-2.9,2.3-5.2,5.2-5.2H74.3c2.9,0,5.2,2.3,5.2,5.2v68.1
								C79.6,76.2,77.2,78.6,74.3,78.6z"/>
							<path class="st2" d="M247.1,199H126.7c-2.9,0-5.2-2.3-5.2-5.2v-68.1c0-2.9,2.3-5.2,5.2-5.2h120.4c2.9,0,5.2,2.3,5.2,5.2v68.1
								C252.4,196.7,250,199,247.1,199z"/>
						</g>
						<g>
							<path class="st3" d="M247.1,78.6H126.7c-2.9,0-5.2-2.3-5.2-5.2V5.2c0-2.9,2.3-5.2,5.2-5.2h120.4c2.9,0,5.2,2.3,5.2,5.2v68.1
								C252.4,76.2,250,78.6,247.1,78.6z"/>
							<path class="st3" d="M74.3,199H-46.1c-2.9,0-5.2-2.3-5.2-5.2v-68.1c0-2.9,2.3-5.2,5.2-5.2H74.3c2.9,0,5.2,2.3,5.2,5.2v68.1
								C79.6,196.7,77.2,199,74.3,199z"/>
						</g>
						<g>
							<path class="st4" d="M97.5,114.4c0,0,3.1,1.1,2.7,5.1h1.3c0,0-0.8-4.5-1.5-5.1S97.5,114.4,97.5,114.4z"/>
							<path class="st4" d="M100.6,113.8c-1.3,0-3.6,0-6.1-0.7c0.5-0.6,1.3-1.7,1.3-3.7c0-3.1-2.1-1.1-2.1-1.1s-1.4,1.2-0.6,4.4
								c-0.8-0.3-1.7-0.7-2.4-1.3c0.5-0.6,1.5-2,2-4.3c0,0-3.6-0.7-4.6,2c-0.6-0.8-1.1-1.6-1.5-2.6c0.9,0,2.2-0.6,2.2-4.1
								c0,0-2.3,0-3,2c-0.2-0.8-0.4-1.8-0.6-2.7c0.9,0.3,3.3,0.5,3.3-4.8c0,0-2.9,0-3.6,2.6c0-0.7-0.1-1.3,0-2
								c1.4-0.7,3.3-2.4,3.1-5.7c0,0-1.3,0.3-2.6,1.3c0.2-1.3,0.1-3.1-1.8-4.6c0,0-1.8,3.1,0.8,5.7c-0.2,0.3-0.4,0.7-0.6,1.1
								c-0.6-0.9-1.5-1.7-3-2.2c0,0-0.7,4.2,3.2,6c0.1,2.5,0.5,4.6,1.1,6.5c-1.1-1-2-2.4-2-2.4c0-4.7-3.8-4.8-3.8-4.8
								c-0.7,5.3,3.2,5.5,3.2,5.5l2.2,2.4c-4.7-0.8-4.7,3.1-4.7,3.1c3.1,0.8,4.7-0.8,4.7-0.8l1.7,0.9c-3,0.1-4,3-4,3
								c3.8,0.6,6.5-0.3,7.4-0.6c3.8,2.6,8.4,2.6,10.6,2.6L100.6,113.8L100.6,113.8L100.6,113.8z"/>
							<path class="st4" d="M100.4,113.8c1.3,0,3.6,0,6.1-0.7c-0.5-0.6-1.3-1.7-1.3-3.7c0-3.1,2.1-1.1,2.1-1.1s1.4,1.2,0.6,4.4
								c0.8-0.3,1.7-0.7,2.4-1.3c-0.5-0.6-1.5-2-2-4.3c0,0,3.6-0.7,4.6,2c0.6-0.8,1.1-1.6,1.5-2.6c-0.9,0-2.2-0.6-2.2-4.1
								c0,0,2.3,0,3,2c0.2-0.8,0.4-1.8,0.6-2.7c-0.9,0.3-3.3,0.5-3.3-4.8c0,0,2.9,0,3.6,2.6c0-0.7,0.1-1.3,0-2
								c-1.4-0.7-3.3-2.4-3.1-5.7c0,0,1.3,0.3,2.6,1.3c-0.2-1.3-0.1-3.1,1.8-4.6c0,0,1.8,3.1-0.8,5.7c0.2,0.3,0.4,0.7,0.6,1.1
								c0.6-0.9,1.5-1.7,3-2.2c0,0,0.7,4.2-3.2,6c-0.1,2.5-0.5,4.6-1.1,6.5c1.1-1,2-2.4,2-2.4c0-4.7,3.8-4.8,3.8-4.8
								c0.7,5.3-3.2,5.5-3.2,5.5l-2.2,2.4c4.7-0.8,4.7,3.1,4.7,3.1c-3.1,0.8-4.7-0.8-4.7-0.8l-1.7,0.9c3,0.1,4,3,4,3
								c-3.8,0.6-6.5-0.3-7.4-0.6c-3.8,2.6-8.4,2.6-10.6,2.6L100.4,113.8L100.4,113.8L100.4,113.8z"/>
						</g>
						<path class="st5" d="M87.4,79.9c-2.7,1.8-5.1,4-7.1,6.6c0.8,0.6,1.7,1.2,2.5,1.8c1.7-2.3,3.8-4.3,6.3-5.8L87.4,79.9z"/>
						<path class="st6" d="M100.5,100.1h10.9v6.2c0,1.7-1.4,3.1-3.1,3.1h-4.7c-1.3,0-2.4,0.6-3.1,1.6L100.5,100.1L100.5,100.1z"/>
						<polygon class="st7" points="102.8,93.1 102.8,84.6 114.5,84.6 111.4,87.7 111.4,96.2 102.8,96.2 				"/>
						<rect x="107.5" y="96.2" class="st8" width="3.9" height="3.9"/>
						<path class="st6" d="M100.5,100.1H89.6v6.2c0,1.7,1.4,3.1,3.1,3.1h4.7c1.3,0,2.4,0.6,3.1,1.6L100.5,100.1L100.5,100.1z"/>
						<path class="st8" d="M98.6,109.7c0.8,0.2,1.4,0.7,1.9,1.4l0,0c0.5-0.6,1.2-1.1,1.9-1.4v-6.4h-3.9L98.6,109.7L98.6,109.7z"/>
						<polygon class="st7" points="98.2,93.1 98.2,84.6 86.5,84.6 89.6,87.7 89.6,96.2 98.2,96.2 				"/>
						<rect x="89.6" y="96.2" class="st8" width="3.9" height="3.9"/>
						<polygon class="st5" points="93.5,88.5 93.5,90.8 95.8,92.3 95.8,90.8 				"/>
						<polygon class="st8" points="95.8,90.8 95.8,93.1 99,94.7 99,93.9 				"/>
						<polygon class="st5" points="107.5,88.5 107.5,90.8 105.2,92.3 105.2,90.8 				"/>
						<polygon class="st8" points="105.2,90.8 105.2,93.1 102.1,94.7 102.1,93.9 				"/>
						<path class="st9" d="M105.2,105.6h-9.4c-2.1,0-3.9-1.7-3.9-3.9v-12l8.6,5.8l8.5-5.8v12C109.1,103.8,107.3,105.6,105.2,105.6z"/>
						<g>
							<polygon class="st5" points="95.1,91.6 91.9,89.2 91.9,97.8 95.1,99.4 					"/>
							<polygon class="st5" points="106,91.6 109.1,89.2 109.1,97.8 106,99.4 					"/>
							<rect x="95.8" y="99.4" class="st5" width="9.3" height="3.9"/>
						</g>
						<polygon class="st9" points="95.8,92.3 100.5,94.7 105.2,92.3 105.2,99.4 95.8,99.4 				"/>
						<g>
							<path class="st8" d="M104,93.1v7.8c0,0.6-0.5,1.2-1.2,1.2h-4.7c-0.6,0-1.2-0.5-1.2-1.2v-7.8l-2.3-1.6v9.4
								c0,1.9,1.6,3.5,3.5,3.5h4.7c1.9,0,3.5-1.6,3.5-3.5v-9.4L104,93.1z"/>
							<path class="st8" d="M104.8,98.5c-1.3,0.1-2.8,0.3-4.3,0.8c-1.5-0.5-3-0.7-4.3-0.8c-2.2-0.2-3.5-0.4-4.2-1.7V99
								c1.1,0.7,2.6,0.9,4.1,1c1.4,0.1,2.8,0.2,4.2,0.8l0.3,0.1l0.3-0.1c1.4-0.5,2.8-0.7,4.2-0.8c1.5-0.1,3-0.3,4.1-1v-2.3
								C108.3,98.1,107,98.3,104.8,98.5z"/>
						</g>
						<polygon class="st10" points="102.1,91.2 100.9,91.2 100.9,90 100.1,90 100.1,91.2 99,91.2 99,92 100.1,92 100.1,95.5 
							100.9,95.5 100.9,92 102.1,92 				"/>
						
							<rect x="96.2" y="86.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.2609 94.9346)" class="st11" width="0.6" height="9.7"/>
						<polygon class="st10" points="91.8,86.6 92.6,88.3 93.5,87.4 				"/>
						
							<rect x="97.1" y="85.8" transform="matrix(0.8083 -0.5888 0.5888 0.8083 -34.7076 74.7433)" class="st11" width="0.6" height="9.7"/>
						<polygon class="st10" points="93.5,85.3 94.1,87.1 95.1,86.4 				"/>
						
							<rect x="95.4" y="87.3" transform="matrix(0.5725 -0.8199 0.8199 0.5725 -34.6021 117.8827)" class="st11" width="0.6" height="9.7"/>
						<polygon class="st10" points="90.2,88.3 91.4,89.8 92.1,88.8 				"/>
						
							<rect x="99.7" y="91" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -33.9269 100.6754)" class="st11" width="9.7" height="0.6"/>
						<polygon class="st10" points="109.2,86.6 108.4,88.3 107.5,87.4 				"/>
						
							<rect x="98.7" y="90.3" transform="matrix(0.5888 -0.8083 0.8083 0.5888 -30.6793 120.9659)" class="st11" width="9.7" height="0.6"/>
						<polygon class="st10" points="107.5,85.3 106.9,87.1 105.9,86.4 				"/>
						
							<rect x="100.5" y="91.8" transform="matrix(0.8199 -0.5725 0.5725 0.8199 -33.7482 76.8941)" class="st11" width="9.7" height="0.6"/>
						<polygon class="st10" points="110.8,88.3 109.6,89.8 108.9,88.8 				"/>
						<path class="st8" d="M100.1,109.3c0,0-3-3.2-2.4-6.2c0.5-2.4,1.9-5,3-5.7c0,0,1.6-0.1,1.2,1.3s-0.9,1.7-0.7,3.1
							C101.4,104.1,101.8,106.8,100.1,109.3z"/>
						<path class="st5" d="M101,97.4c-0.2,0-0.3,0-0.3,0c-1.1,0.6-2.5,3.3-3,5.7c-0.3,1.3,0.2,2.8,0.8,3.9
							C98.1,102,99.5,100.3,101,97.4z"/>
						<path class="st9" d="M101.1,101.7c-0.1-1.3,0.3-1.7,0.7-3.1c0.3-1-0.4-1.2-0.8-1.3c0,0-1,2.3-1.1,3.9c-0.1,1.4,0.3,6.9,0.2,8
							C101.8,106.8,101.4,104.1,101.1,101.7z"/>
						<path class="st12" d="M99.1,98.6c0.6-0.6,1.5-0.9,2.4-0.7c-0.4-1.3-0.8-2.6-1.2-4c-0.9-0.1-1.7,0.1-2.4,0.7
							C98.3,95.9,98.7,97.2,99.1,98.6z"/>
						<path class="st8" d="M101.5,97.8c0.6-0.6,1.5-0.9,2.4-0.7c-0.4-1.3-0.8-2.6-1.2-4c-0.9-0.1-1.7,0.1-2.4,0.7
							C100.7,95.2,101.1,96.5,101.5,97.8z"/>
						<path class="st7" d="M97,80.7c-4.2-0.1-8.4,0.9-12.1,3c-0.5-0.9-1-1.8-1.5-2.7c4.2-2.3,8.9-3.5,13.7-3.4
							C97,78.7,97,79.7,97,80.7z"/>
						<path class="st5" d="M113.6,79.9c2.7,1.8,5.1,4,7.1,6.6c-0.8,0.6-1.7,1.2-2.5,1.8c-1.7-2.3-3.8-4.3-6.3-5.8L113.6,79.9z"/>
						<path class="st7" d="M104.1,80.7c4.2-0.1,8.4,0.9,12.1,3c0.5-0.9,1-1.8,1.5-2.7c-4.2-2.3-8.9-3.5-13.7-3.4
							C104,78.7,104,79.7,104.1,80.7z"/>
						<path class="st5" d="M106.7,79.9c-4.1-1.1-8.4-1.1-12.5,0c-0.3-1-0.5-2-0.8-3c4.6-1.3,9.5-1.3,14.1,0
							C107.3,77.9,107,78.9,106.7,79.9z"/>
						<g>
							<path class="st6" d="M90.1,114.8c-3.4,0.9-6.6,2.1-9.8,3.6c0.4,0.9,0.9,1.9,1.3,2.8c3.1-1.4,6.2-2.6,9.5-3.4L90.1,114.8z"/>
							<path class="st6" d="M110.9,114.8c3.4,0.9,6.6,2.1,9.8,3.6c-0.4,0.9-0.9,1.9-1.3,2.8c-3.1-1.4-6.2-2.6-9.5-3.4L110.9,114.8z"/>
						</g>
						<g>
							<path class="st9" d="M113,117.2c-8.2-2-16.7-2-24.9,0c-0.2-1-0.5-2-0.7-3c8.7-2.1,17.7-2.1,26.4,0
								C113.5,115.2,113.3,116.2,113,117.2z"/>
							<path class="st9" d="M100.5,118l-2.1,0.5c-0.5,0.1-1-0.2-1-0.8v-1.1c0-0.5,0.5-0.9,1-0.8l2.1,0.5V118z"/>
							<path class="st9" d="M100.5,116.5l2.1-0.5c0.5-0.1,1,0.2,1,0.8v1.1c0,0.5-0.5,0.9-1,0.8l-2.1-0.5V116.5z"/>
						</g>
						<path class="st6" d="M100.7,118h-0.3c-0.3,0-0.6-0.3-0.6-0.6v-0.3c0-0.3,0.3-0.6,0.6-0.6h0.3c0.3,0,0.6,0.3,0.6,0.6v0.3
							C101.3,117.8,101,118,100.7,118z"/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
<script>
export default {
    name: 'iconDominicanFlag',    
    props: {
        size: {
            type: Number,
            default: 20
        },
        enableBackground: {
			type: Boolean
        }
    }
}
</script>

<style type="text/css" scoped>
.flag-container {
	height: 22px;
	width: 23px;

	padding: 2px;
	border-radius: 50%;
}
	.st0{clip-path:url(#SVGID_2_);}
	.st1{fill:#FFFFFF;}
	.st2{fill:#2E3191;}
	.st3{fill:#CF2127;}
	.st4{fill:#64B496;}
	.st5{fill:#41479B;}
	.st6{fill:#FF6B71;}
	.st7{fill:#5F64B9;}
	.st8{fill:#F5F5F5;}
	.st9{fill:#FF4B55;}
	.st10{fill:#FFE15A;}
	.st11{fill:#464655;}
	.st12{fill:#F5F0F0;}

</style>

